import StepsProgressIndicator from "./components/StepsProgressIndicator";
import FeaturedTextBlock from "./components/FeaturedTextBlock";
import MoreIntegrations from "./components/MoreIntegrations";
import AuthRow from "./components/AuthRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { SHOPIFY_QBO_URL } from "util/helperFunctions";

const StepTwo = ({ intOne, intTwo }) => {
  // eslint-disable-next-line max-len
  const url = `${intTwo.authorize_url}&callback_redirect_url=${process.env.REACT_APP_SELF_URL}${SHOPIFY_QBO_URL}/three`;

  const params = new URLSearchParams(document.location.search);
  const connOneId = params.get("connection_id") || localStorage.getItem("connection_id");
  if (connOneId) {
    localStorage.setItem("connOneId", connOneId);
  }

  return (
    <div className="container max-w-screen-lg">
      <FeaturedTextBlock />

      <div className="featured-action-container">
        <StepsProgressIndicator
          currentStep={1}
          nameOne={intOne.name}
          nameTwo={intTwo.name}
        />
        <div className="featured-integrations-container">
          <AuthRow
            imageUrl={intOne.logo}
            isAuthenticated={true}
            name={intOne.name}
          />
          <FontAwesomeIcon
            title="plus"
            icon={faCirclePlus}
            className={"text-6xl text-off-black"}
          />
          <AuthRow
            imageUrl={intTwo.logo}
            onClick={() => window.open(url, "_self")}
            name={intTwo.name}
          />
        </div>
      </div>
      <MoreIntegrations />
    </div>
  );
};

export default StepTwo;
