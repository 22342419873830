import { useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import TextInput from "common/TextInput";
import Button from "common/Button";
import { SHOPIFY_QBO_URL } from "util/helperFunctions";

const Login = () => {
  const { search } = useLocation();
  const redirect = new URLSearchParams(search).get("redirect") || `${SHOPIFY_QBO_URL}/one`;

  const { logUserIn, authData } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  if (authData?.isLoggedIn) return <Navigate to={redirect} />;

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    
    const { ok, error } = await logUserIn(email, password);

    if (ok) navigate(redirect, { replace: true });
      
    setError(error);
    setIsLoading(false);
  };

  return (
    <div id="login-page">
      <h1 className="auth-heading">Sign In</h1>

      <div className="auth-text">
        <p>Sign in to FlowLink to connect your Shopify store.</p>
        <p>Don&apos;t have an account?</p>
        <a href="/signup">Create an account to get started</a>.
      </div>
      <form
        onSubmit={handleSubmit}
        method="post"
        id="login-form"
        className="auth-form"
      >
        {error && (
          <div className="form-error block" aria-live="polite">
            {error}
          </div>
        )}
        <div className="form-col">
          <TextInput
            placeholder="Email"
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            placeholder="****"
            label="Password"
            type="password"
            name="password"
            autoComplete="current-password"
            required={true}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <Button type="submit" className="button-secondary self-center">
            Sign In
          </Button>
        )}
        <p className="mt-2 text-center">
          <a href="/password/reset">Forgot password?</a>
        </p>
      </form>
    </div>
  );
};

export default Login;
