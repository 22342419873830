import { useAuth } from "hooks/useAuth";
import SidebarLink from "./SidebarLink";
import Button from "common/Button";
import {
  faHouse,
  faFileInvoiceDollar,
  faBuildings,
} from "@fortawesome/pro-solid-svg-icons";

const LINKS = [
  { route: "/", text: "Dashboard", icon: faHouse, adminOnly: false },
  {
    route: "/billing",
    text: "Billing",
    icon: faFileInvoiceDollar,
    adminOnly: false,
  },
  { route: "/companies", text: "Companies", icon: faBuildings, adminOnly: true },
];

const Sidebar = () => {
  const { logUserOut, authData } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    logUserOut();
  };

  return (
    <nav
      id="sidebar"
      className="overflow-y-auto bg-off-black p-4 text-off-white md:h-full md:col-span-2"
    >
      <div>FlowLink</div>
      <ul>
        {LINKS.map((l) => (
          <SidebarLink
            key={l.route}
            isVisible={!l.adminOnly || authData?.isAdmin}
            link={l}
          />
        ))}
        <li>
          <form onSubmit={handleSubmit} method="delete" id="logout-form">
            <Button type="submit" className="">
              Logout
            </Button>
          </form>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
