import { useState } from "react";
import { useNavigate } from "react-router-dom";
import StepsProgressIndicator from "./components/StepsProgressIndicator";
import CustomizeDataTypes from "./components/CustomizeDataTypes";
import { SHOPIFY_QBO_URL } from "util/helperFunctions";

const StepThree = ({ intOne, intTwo }) => {
  const navigate = useNavigate();

  const [dataTypes, setDataTypes] = useState(() => {
    const dts = localStorage.getItem("dataTypes");
    return dts ? JSON.parse(dts) : [];
  });

  const params = new URLSearchParams(document.location.search);
  const connTwoId = params.get("connection_id");
  if (connTwoId) {
    localStorage.setItem("connTwoId", connTwoId);
  }

  const handleToggle = (type) => {
    const idx = dataTypes.findIndex((t) => t === type);
    if (idx === -1) {
      setDataTypes((prev) => [...prev, type]);
    } else {
      setDataTypes((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1)]);
    }
  };

  const handleNextClicked = () => {
    localStorage.setItem("dataTypes", JSON.stringify(dataTypes));
    navigate(`${SHOPIFY_QBO_URL}/four`);
  };

  return (
    <div className="container max-w-screen-lg">
      <div className="featured-action-container">
        <StepsProgressIndicator
          currentStep={2}
          nameOne={intOne.name}
          nameTwo={intTwo.name}
        />
        <CustomizeDataTypes
          onClick={handleNextClicked}
          dataTypes={dataTypes}
          setDataTypes={handleToggle}
        />
      </div>
    </div>
  );
};

export default StepThree;
