import StepsProgressIndicator from "./components/StepsProgressIndicator";
import FeaturedTextBlock from "./components/FeaturedTextBlock";
import MoreIntegrations from "./components/MoreIntegrations";
import AuthRow from "./components/AuthRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { SHOPIFY_QBO_URL } from "util/helperFunctions";

const StepOne = ({ intOne, intTwo }) => {
  // eslint-disable-next-line max-len
  const url = `${intOne.authorize_url}&callback_redirect_url=${process.env.REACT_APP_SELF_URL}${SHOPIFY_QBO_URL}/two`;

  return (
    <div className="container max-w-screen-lg">
      <FeaturedTextBlock />
      <div className="w-full">
        <div className="featured-action-container">
          <StepsProgressIndicator
            currentStep={0}
            nameOne={intOne.name}
            nameTwo={intTwo.name}
          />
          <div className="featured-integrations-container">
            <AuthRow
              imageUrl={intOne.logo}
              onClick={() => window.open(url, "_self")}
              name={intOne.name}
            />
            <FontAwesomeIcon
              title="plus"
              icon={faCirclePlus}
              className={"text-6xl text-off-black"}
            />
            <AuthRow
              imageUrl={intTwo.logo}
              onClick={() => null}
              name={intTwo.name}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <MoreIntegrations />
    </div>
  );
};

export default StepOne;
