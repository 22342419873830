import { useId } from "react";

const TextInput = ({
  type = "text",
  id,
  value,
  onChange,
  disabled,
  className = "input-default",
  required,
  errorMessage,
  hint,
  label,
  name,
  autoComplete,
  ...rest
}) => {
  const inputId = useId();
  const descriptionId = useId();

  return (
    <div className={`input-wrapper ${errorMessage ? "errored" : ""}`}>
      <label htmlFor={id || inputId} className="input-label">
        {label}
        {required && <span className="input-required-star">*</span>}
      </label>
      <input
        type={type}
        id={id || inputId}
        aria-describedby={hint && descriptionId}
        {...{
          className,
          type,
          value,
          onChange,
          disabled,
          required,
          name,
          autoComplete,
          ...rest,
        }}
      />
      {hint && (
        <p id={descriptionId} className="input-hint">
          {hint}
        </p>
      )}
    </div>
  );
};

export default TextInput;
