import { useQuery } from "react-query";
import { getReq } from "util/apiQueries";
import CompanyRow from "./CompanyRow";

const Companies = () => {
  const { data, isFetching } = useQuery("companies", () =>
    getReq(`/companies`)
  ); // TODO: Add pagination

  if (isFetching) return <div>Loading...</div>;

  if (!data) return <div>No Companies</div>;

  return (
    <div id="companies-page">
      <table>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Status</th>
            <th scope="col">Latest Invoice</th>
            <th scope="col">Integrations</th>
          </tr>
        </thead>
        <tbody>
          {data?.companies?.map((company, i) => {
            return <CompanyRow company={company} key={company.id} index={i} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Companies;
