import { useState } from "react";
import { useQuery } from "react-query";
import InvoiceCard from "./InvoiceCard";
import BasicSelect from "common/BasicSelect";
import { getReq } from "util/apiQueries";
import { formatDate } from "util/helperFunctions";

const BillingCard = ({ id }) => {
  const [selectOptions, setSelectOptions] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);

  const onSuccess = (data) => {
    setSelectOptions(
      data.invoices.map((i) => [i.id, `Invoice on ${formatDate(i.created_at)}`])
    );
    setInvoiceId(data.invoices[0].id);
  };

  const { data, isFetching } = useQuery(
    ["invoices", id],
    () => getReq(`/companies/${id}/invoices`),
    {
      onSuccess,
    }
  );

  if (isFetching) return <div>Loading...</div>;

  if (!data) return <div>No Invoices Found</div>;

  return (
    <div>
      <BasicSelect
        options={selectOptions}
        value={invoiceId}
        onChange={(e) => setInvoiceId(e.target.value)}
        aria-label="Invoice"
        disabled={false}
      />
      <InvoiceCard invoiceId={invoiceId} companyId={id} />
    </div>
  );
};

export default BillingCard;
