import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
// import { getReq } from "util/apiQueries";
import InvoiceInformation from "./InvoiceInformation";
import { FAKE_INVOICES, FAKE_NETWORK } from "util/fakeData";

const InvoiceDetail = () => {
  const { id, invoiceId } = useParams();

  const getCompanyInvoice = async (_cId, _iId) => {
    await FAKE_NETWORK();
    return FAKE_INVOICES[0];

    // return await getReq(`/companies/${cId}/invoices/${iId}`);
  };

  const { data, isFetching } = useQuery(["invoice", id, invoiceId], () =>
    getCompanyInvoice(id, invoiceId)
  );

  if (isFetching) return <div>Loading...</div>;

  if (!data) return <div>No Invoice Found</div>;

  return (
    <div id={`company-invoice-detail`} key={data.id}>
      <InvoiceInformation invoice={data} />
    </div>
  );
};

export default InvoiceDetail;
