import { createContext, useContext, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { login, logout, signup, userInfo } from "util/apiQueries";
import { AUTH_TOKEN_NAME } from "util/helperFunctions";
import { useLocalStorage } from "./useLocalStorage";

// Auth stuff is/can be utilized all over our tree, so we create a context for it here
// This can be used anywhere we need the auth information
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Ok, we store out token for auth here using localstorage hook
  const [authData, setAuthData] = useLocalStorage(AUTH_TOKEN_NAME, null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // We package up our auth data/functions into a memoized object
  const authContextValue = useMemo(() => {
    // logs the user in (calling the react query fn) and then stores the token and navigates away
    const logUserIn = async (email, password) => {
      const { msg, ok } = await login(email, password);
      
      if (ok) {
        const { role } = await userInfo();
        console.log('User role:', role)
        
        setAuthData({ isAdmin: role == 'admin' ? true : false, isLoggedIn: true });
      }
      
      return { error: msg, ok };
    };

    // trashes the token, logs the user out (calling the react query fn),
    // invalidates data, and navigates away)
    const logUserOut = async () => {
      setAuthData(null);
      const response = await logout();
      console.log("logged out response", response);
      await queryClient.invalidateQueries();
      navigate("/login", { replace: true });
    };

    const createAccount = async (data) => {
      const response = await signup(data);
      console.log("RESPONSE in createAcc", response);
      if (!response.ok) return { error: response.msg, ok: response.ok };

      setAuthData({ isAdmin: false, isLoggedIn: true });

      return { error: null, ok: true };
    };

    return { authData, logUserIn, logUserOut, createAccount };
  }, [authData, setAuthData, navigate, queryClient]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
