import React from "react";
import { Route, Routes } from "react-router-dom";
import { QueryClient, useQueryClient } from "react-query";
import { useAuth } from "hooks/useAuth";
import ProtectedRoute from "./Pages/ProtectedRoute";
import AdminRoute from "Pages/AdminRoute";
import Dashboard from "./Pages/Dashboard";
import Auth from "Pages/Auth";
import Login from "./Pages/Auth/Login";
import Signup from "./Pages/Auth/Signup";
import Connection from "Pages/Connection";
import Companies from "./Pages/Companies";
import CompanyDetail from "./Pages/Companies/CompanyDetail";
import InvoiceDetail from "./Pages/Companies/Invoices/InvoiceDetail";
import NewInvoice from "./Pages/Companies/Invoices/NewInvoice";
import Billing from "Pages/Billing";
import AppView from "Pages/AppView";
import NotFound from "Pages/NotFound";
import StepOne from "Pages/Connection/StepOne";
import StepTwo from "Pages/Connection/StepTwo";
import StepThree from "Pages/Connection/StepThree";
import StepFour from "Pages/Connection/StepFour";
import StepFive from "Pages/Connection/StepFive";
import PasswordReset from "Pages/Auth/PasswordReset";
import PasswordEdit from "Pages/Auth/PasswordEdit";

const App = () => {
  const { logUserOut } = useAuth();
  // I wanted to add this config when we create the queryClient, but we
  // need to access the `logUserOut` from the Auth context
  const queryClient = useQueryClient();
  queryClient.setDefaultOptions({
    queries: {
      // https://github.com/TanStack/query/discussions/1385#discussioncomment-3113979
      retry: (failureCount, err) => {
        if (err.response?.status === 401) return false;

        const def = new QueryClient().getDefaultOptions().queries?.retry;
        return Number.isSafeInteger(def) ? failureCount < (def ?? 0) : false;
      },
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (err.message === "Unauthorized") {
          logUserOut();
        }
        return err;
      },
    },
  });

  return (
    <Routes>
      <Route
        path={"/login"}
        element={
          <Auth>
            <Login />
          </Auth>
        }
      />
      <Route
        path={"/signup"}
        element={
          <Auth>
            <Signup />
          </Auth>
        }
      />
      <Route
        path={"/password/reset"}
        element={
          <Auth>
            <PasswordReset />
          </Auth>
        }
      />
      <Route
        path={"/password/edit"}
        element={
          <Auth>
            <PasswordEdit />
          </Auth>
        }
      />

      {/* Logged in routes: */}
      <Route element={<ProtectedRoute />}>
        <Route
          path="connection/:integration_one/:integration_two/one"
          element={
            <Connection>
              <StepOne />
            </Connection>
          }
        />
        <Route
          path="connection/:integration_one/:integration_two/two"
          element={
            <Connection>
              <StepTwo />
            </Connection>
          }
        />
        <Route
          path="connection/:integration_one/:integration_two/three"
          element={
            <Connection>
              <StepThree />
            </Connection>
          }
        />
        <Route
          path="connection/:integration_one/:integration_two/four"
          element={
            <Connection>
              <StepFour />
            </Connection>
          }
        />
        <Route
          path="connection/:integration_one/:integration_two/five"
          element={
            <Connection>
              <StepFive />
            </Connection>
          }
        />
      </Route>

      {/* Admin only routes: */}
      <Route element={<AdminRoute />}>
        {/* Hide dashboard from guest users until we have an actual dashboard */}
        <Route
          index={true}
          element={
            <AppView>
              <Dashboard />
            </AppView>
          }
        />
        <Route
          path="companies/"
          element={
            <AppView>
              <Companies />
            </AppView>
          }
        />
        <Route
          path="companies/:id/"
          element={
            <AppView>
              <CompanyDetail />
            </AppView>
          }
        />
        <Route
          path="companies/:id/invoices/new"
          element={
            <AppView>
              <NewInvoice />
            </AppView>
          }
        />
        <Route
          path="companies/:id/invoices/:invoiceId"
          element={
            <AppView>
              <InvoiceDetail />
            </AppView>
          }
        />
        <Route
          path="billing"
          element={
            <AppView>
              <Billing />
            </AppView>
          }
        />
      </Route>
      
      <Route
        path="/*"
        element={<NotFound />}
      />

    </Routes>
  );
};

export default App;
