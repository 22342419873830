import React from "react";

const Loader = () => {
  return (
    <div className="w-screen h-screen bg-fl-green z-100 absolute left-0 top-0">
      <div className="flex flex-col gap-y-8 items-center px-2 md:w-1/3 mx-auto h-screen justify-center">
        <span className="loader"></span>
        <div className="text-2xl lg:text-4xl text-off-black text-center">
          Just a moment while we create the data sync for you...
        </div>
      </div>
    </div>
  );
};

export default Loader;
