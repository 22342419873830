const BasicSelect = ({
  options = [], // 2D array of ['value', 'label'] or ['value']
  children,
  id,
  value,
  name,
  className = "select-default",
  onChange,
  ...rest
}) => {
  const selectOptions = options.map(([value, label]) => {
    return (
      <option value={value} key={value}>
        {label || value}
      </option>
    );
  });
  return (
    <select
      className={className}
      id={id}
      value={value}
      name={name}
      onChange={onChange}
      {...rest}
    >
      {selectOptions.length ? selectOptions : children}
    </select>
  );
};

export default BasicSelect;
