import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import NotFound from "Pages/NotFound";

const AdminRoute = () => {
  const { authData } = useAuth();
  if (!authData?.isLoggedIn) return <Navigate to="/signup" />;
  if (!authData?.isAdmin) return <NotFound />;
  return <Outlet />;
};

export default AdminRoute;
