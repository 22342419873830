// import Connections from "./Connections";
// import MonthCard from "./MonthCard";
// import Notifications from "./Notifications";

const Dashboard = () => {
  return (
    <div id="dashboard-page">
      <h1>Dashboard</h1>
      <div>Current Company Dashboard here</div>
      <div className="border-red-600 border-solid border-2 p-2">
        <strong>DEV LINK - </strong>{" "}
        <a href="/connection/shopify/qbo/one">Shopify Connection flow</a>
      </div>

      {/* <div className="border-solid border-2 rounded p-4">
        <MonthCard />
      </div>
      <div className="border-solid border-2 rounded p-4">
        <Connections />
      </div>

      <div className="border-solid border-2 rounded p-4">
        <Notifications />
      </div> */}
    </div>
  );
};

export default Dashboard;
