import AppView from "Pages/AppView";
import annMadAf from 'assets/fl-branding/ann-mad-af.svg';

const NotFound = () => {
  return (
    <AppView>
      <div id="not-found-page">
        <h1>Not Found.</h1>
        <img
          src={annMadAf}
          role="presentation"
          className="h-64 my-8"
        />
        <p className="py-6">
          Sorry, we could not find what you were looking for. If you think
          that you reached this page by error, please email us at&nbsp;
          <a href="mailto:info@flowlink.io" className="underline hover:text-fl-green-300 font-bold">info@flowlink.io</a>.

        </p>
      </div>
    </AppView>
  );
};

export default NotFound;
