import flowLinkLogo from "assets/fl-branding/flowlink-logo-bicolor-white.svg";

const Auth = ({ children, ...props }) => {
  return (
    <div className="auth-wrapper">
      <div className="mx-auto p-8 max-w-screen-sm" {...props}>
        <img
          src={flowLinkLogo}
          role="img"
          alt="FlowLink"
          className="h-[64px] mx-auto my-8"
          height={64}
        />
        {children}
      </div>
    </div>
  );
};

export default Auth;
