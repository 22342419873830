const DEFAULT_DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const SITE_URL = process.env.REACT_APP_SITE_URL;

export const AUTH_TOKEN_NAME = "fl_token";

export const SHOPIFY_QBO_URL = "/connection/shopify/qbo";

export const formatDate = (dateString, options = DEFAULT_DATE_OPTIONS) => {
  if (!dateString) return "--";
  return new Date(dateString).toLocaleDateString("en-us", options);
};
