import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import TextInput from "common/TextInput";
import Button from "common/Button";
import { SHOPIFY_QBO_URL } from "util/helperFunctions";

const Signup = () => {
  const { search } = useLocation();

  const redirect = new URLSearchParams(search).get("redirect") || `${SHOPIFY_QBO_URL}/one`;

  const { createAccount, authData } = useAuth();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  // TODO: Do we need to clear the error?
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  if (authData?.isLoggedIn) return <Navigate to={redirect} />;

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const { ok, error } = await createAccount({
      firstName,
      lastName,
      email,
      company,
      password,
      passwordConfirmation,
    });

    if (ok) return <Navigate to={redirect} />;

    setError(error);
    setIsLoading(false);
  };
  return (
    <div id="signup-page">
      <h1 className="auth-heading">Create An Account</h1>
      <div className="auth-text">
        <p>
          Create an account to get started with connecting your Shopify store.
        </p>
        <p>Already have an account?</p>
        <p>
          <a href={`/login?redirect=${redirect}`}>Sign in to get started.</a>
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        method="post"
        id="signup-form"
        className="auth-form"
      >
        {error && (
          <div className="form-error block" aria-live="polite">
            {error}
          </div>
        )}

        <div className="form-row">
          <TextInput
            label="First Name"
            type="text"
            autoComplete="given-name"
            required={true}
            name="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextInput
            label="Last Name"
            type="text"
            autoComplete="family-name"
            required={true}
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <TextInput
          label="Email Address"
          type="email"
          autoComplete="email"
          required={true}
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextInput
          label="Company Name"
          type="text"
          autoComplete="organization"
          required={true}
          name="company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        <div className="form-row">
          <TextInput
            label="Password"
            hint="Must contain 10 characters, 2 numbers and a symbol"
            type="password"
            autoComplete="new-password"
            required={true}
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextInput
            label="Password Confirmation"
            type="password"
            autoComplete="new-password"
            required={true}
            name="passwordConfirmation"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
        </div>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <Button type="submit" className="button-secondary self-center">
            Create Account
          </Button>
        )}
      </form>
    </div>
  );
};

export default Signup;
