const Button = ({ children, className, onClick, ...rest }) => {
  className = "button-rounded button-default " + className;

  return (
    <button className={className} onClick={onClick} {...rest}>
      {children}
    </button>
  );
};

export default Button;
