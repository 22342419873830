import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import Button from "common/Button";
import { SHOPIFY_QBO_URL } from "util/helperFunctions";

const PasswordSuccess = ({ title, body }) => {
  const { authData } = useAuth();
  const navigate = useNavigate();

  if (authData?.isLoggedIn) return <Navigate to={`${SHOPIFY_QBO_URL}/one`} />;

  return (
    <div id="login-page">
      <h1 className="auth-heading">{title}</h1>

      <div className="auth-text">
        <p>{body}</p>
        <Button
          className="button-secondary self-center"
          onClick={() => navigate("/login")}
        >
          Back to Login
        </Button>
      </div>
    </div>
  );
};

export default PasswordSuccess;
