import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import Loader from "Loader";
import NextBtn from "./components/NextBtn";
import StepsProgressIndicator from "./components/StepsProgressIndicator";

import { SHOPIFY_QBO_URL } from "util/helperFunctions";
import { FAKE_INTEGRATION_DATA_STREAMS } from "util/fakeData";
import { createWorkflows } from "util/apiQueries";

const StepFour = ({ intOne, intTwo }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const connOneId = localStorage.getItem("connOneId");
  if (!connOneId) navigate(`${SHOPIFY_QBO_URL}/one`);

  const connTwoId = localStorage.getItem("connTwoId");
  if (!connTwoId) navigate(`${SHOPIFY_QBO_URL}/two`);

  const dataTypes = JSON.parse(localStorage.getItem("dataTypes"));
  if (!dataTypes) navigate(`${SHOPIFY_QBO_URL}/three`);

  const mutation = useMutation({
    mutationFn: createWorkflows,
    onSuccess: ({ ok, msg }) => {
      setIsLoading(false);
      if (!ok) {
        setError(msg);
        return;
      }

      navigate(`${SHOPIFY_QBO_URL}/five`);
    },
  });

  if (isLoading) return <Loader />;

  return (
    <div className="container max-w-screen-lg">
      <div className="featured-action-container">
        <StepsProgressIndicator
          currentStep={3}
          nameOne={intOne.name}
          nameTwo={intTwo.name}
        />
        <div className="flex flex-col items-center">
          {error && (
            <div className="block" aria-live="polite">
              {error}
            </div>
          )}
          <h1 className="mb-8 text-xl font-medium md:text-2xl">
            Please confirm that these are your data streams:
          </h1>
          <ul className="bg-white p-4">
            {dataTypes.map((dataType) => (
              <li
                className="flex items-center justify-between gap-8 p-4 text-lg"
                key={dataType}
              >
                <span>
                  {/* eslint-disable-next-line max-len */}
                  {FAKE_INTEGRATION_DATA_STREAMS.find((e) => e.id === dataType)
                    ?.title || dataType}
                </span>
                <FontAwesomeIcon icon={faCheck} />
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-between">
          <NextBtn
            title="Back"
            iconStart={faChevronLeft}
            onClick={() => navigate(`${SHOPIFY_QBO_URL}/three`)}
          />
          <NextBtn
            title="Save"
            disabled={isLoading}
            iconEnd={faChevronRight}
            onClick={() => {
              setError(null);
              setIsLoading(true);
              mutation.mutate({ dataTypes, connOneId, connTwoId });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepFour;
