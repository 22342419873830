import React from "react";
import { useQuery } from "react-query";
import { getReq } from "util/apiQueries";

const IntegrationCell = ({ id }) => {
  const { data, isFetching } = useQuery(["integration", id], () =>
    getReq(`/integrations/${id}`)
  );

  if (isFetching) return <div>Loading...</div>;

  if (!data) return <div>No Integration Found</div>;

  return <div>{data.name}</div>;
};

export default IntegrationCell;
