import Footer from "Pages/ProtectedRoute/Footer";
import Sidebar from "Pages/ProtectedRoute/Sidebar";

const AppView = ({ children }) => {
  return (
    <div id="home-page" className="flex flex-col h-screen">
      <div className="grid md:grid-cols-12 md:h-full">
        <Sidebar />
        <div className="md:col-span-10 flex flex-col justify-between">
          <main className="overflow-y-auto p-4">
            {children} 
          </main>
          <Footer/>
        </div>
      </div>
      
    </div>
  );
};

export default AppView;
