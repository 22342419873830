import NextBtn from "./NextBtn";
import { useId } from "react";
import { FAKE_INTEGRATION_DATA_STREAMS } from "util/fakeData";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import annFace from "assets/fl-branding/ann-face.png";

const CardToggle = ({ title, description, isToggled, onToggle }) => {
  const borderColor = isToggled ? "border-fl-green-200" : "border-transparent";
  const toggleClass = isToggled
    ? "inset-left-0 left-10 border-fl-green-200"
    : "inset-right-0 left-0 border-gray-600";

  const toggleBg = isToggled ? "bg-fl-green-200" : "bg-gray-300";
  const toggleId = useId();
  const descriptionId = useId();

  return (
    <div
      className={`group flex w-full flex-col gap-4 rounded-lg border-2
      bg-white p-4 shadow-lg transition-colors md:gap-0 ${borderColor}`}
    >
      <div className="flex items-center gap-6">
        <button
          className={`relative flex h-10 w-20 shrink-0 cursor-pointer
          justify-center rounded-full border border-transparent
          transition-colors duration-300 ease-in-out ${toggleBg}`}
          onClick={onToggle}
          id={toggleId}
          aria-checked={isToggled}
          role="switch"
          type="button"
          aria-describedby={descriptionId}
        >
          <span
            className={`absolute aspect-square h-full rounded-full border-[6px]
            bg-white transition-all duration-300 ease-in-out ${toggleClass}`}
          ></span>
        </button>
        <label
          htmlFor={toggleId}
          className="cursor-pointer text-2xl font-medium"
        >
          {title}
        </label>
      </div>
      <p id={descriptionId} className="md:ml-20 md:pl-6">
        {description}
      </p>
    </div>
  );
};

const CustomizeDataTypes = ({ setDataTypes, dataTypes, onClick }) => {
  return (
    <div>
      <div className="card-dark flex items-center justify-center gap-6 p-4 md:p-2">
        <img src={annFace} alt="" className="hidden w-1/4 md:block" />
        <div>
          <p className="mb-2 text-2xl font-semibold text-fl-yellow">
            Welcome back!
          </p>
          <p className="max-w-prose pr-4">
            Now that our connection is secure, it&apos;s time to customize! Flip
            the switch on these data streams from your Shopify to Quickbooks.{" "}
            <strong>You can always edit them later.</strong>
          </p>
        </div>
      </div>
      <fieldset className="my-12 container max-w-screen-sm">
        <legend>
          <h1 className="mb-8 text-2xl font-medium md:text-4xl">
            Create your QuickBooks data streams
          </h1>
        </legend>
        <div className="flex flex-col gap-8">
          {FAKE_INTEGRATION_DATA_STREAMS.map(({ id, title, description }) => (
            <CardToggle
              {...{ title, description }}
              isToggled={dataTypes.includes(id)}
              onToggle={() => setDataTypes(id)}
              key={id}
            />
          ))}
        </div>
      </fieldset>
      <div>
        <NextBtn
          onClick={onClick}
          disabled={!dataTypes.length}
          iconEnd={faChevronRight}
          className="mx-auto"
        />
      </div>
    </div>
  );
};

export default CustomizeDataTypes;
