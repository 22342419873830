import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "util/helperFunctions";
import IntegrationCell from "./IntegrationCell";

const CompanyRow = ({ company, index }) => {
  const maybeLink = () => {
    const date = formatDate(company.latestInvoice?.createdAt);
    if (!company.latestInvoice) return date;

    return (
      <Link
        to={`/companies/${company.id}/invoices/${company.latestInvoice.id}`}
      >
        {formatDate(company.latestInvoice?.createdAt)}
      </Link>
    );
  };

  return (
    <tr id={`company-${index}`} key={company.id}>
      <td>
        <Link to={`/companies/${company.id}`}>{company.name}</Link>
      </td>
      <td>{company.status}</td>
      <td>{maybeLink()}</td>
      <td>
        {company.integrationIds?.map((id) => {
          return (
            <React.Fragment key={id}>
              <IntegrationCell id={id} />
              <br />
            </React.Fragment>
          );
        })}
      </td>
    </tr>
  );
};

export default CompanyRow;
