const Footer = () => {
  return (
    <footer className="flex bg-gray-50 justify-center p-6">
      <div className="text-sm">
        &copy; {(new Date().getFullYear())} <a href="https://flowlink.io" target="_blank" rel="noreferrer" className="underline text-fl-green-300 font-bold hover:text-fl-green-500">FlowLink</a>.
      </div>
    </footer>
  );
};

export default Footer;
