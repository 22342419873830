import { useState } from "react";
import { useQuery } from "react-query";
import BillingCard from "./BillingCard";
import { getReq } from "util/apiQueries";

const Billing = () => {
  const [companyId, setCompanyId] = useState(null);

  const { data, isFetching } = useQuery(
    "companies",
    () => getReq(`/companies`),
    {
      onSuccess: (data) => setCompanyId(data.companies[0].id),
    }
  );

  if (isFetching) return <div>Loading...</div>;

  if (!data) {
    return (
      <div>An error occurred. Please logout and try logging in again.</div>
    );
  }

  return (
    <div id="billing-detail">
      <BillingCard id={companyId} />
    </div>
  );
};

export default Billing;
