export async function FAKE_NETWORK() {
  return new Promise((res) => {
    setTimeout(res, Math.random() * 800);
  });
}

export const FAKE_INVOICES = [
  {
    id: 22,
    companyId: 1,
    createdAt: new Date(),
    plan: { type: "Enterprise", transferLevel: 50000 },
    transfers: 288326,
    overage: 123824,
    charges: {
      amount: "$900.00",
      projectedAmount: "$900.00",
      overageFee: "$2383.26",
      refund: "-$0.00",
      total: "$4183.26",
    },
  },
  {
    id: 21,
    companyId: 1,
    createdAt: new Date(),
    plan: { type: "Growth", transferLevel: 25000 },
    transfers: 6000,
    overage: 0,
    charges: {
      amount: "$525.00",
      projectedAmount: "$525.00",
      overageFee: "$0",
      refund: "-$0.00",
      total: "$525.00",
    },
  },
];

export const FAKE_COMPANIES = [
  {
    id: 1,
    name: "MarcDev",
    status: "live",
    latestInvoice: { createdAt: new Date(), id: 22 },
    invoiceIds: [21, 22],
    integrationIds: [1, 4, 6],
  },
  {
    id: 2,
    name: "DeadClient",
    status: "churn",
    latestInvoice: null,
    invoiceIds: [],
    integrationIds: [],
  },
  {
    id: 3,
    name: "Client ABC",
    status: "onboarding",
    latestInvoice: null,
    invoiceIds: [],
    integrationIds: [3, 4, 5],
  },
];

export const FAKE_NEW_INVOICE = {
  id: 24,
  companyId: 1,
  createdAt: new Date(),
  plan: { type: "Growth", transferLevel: 25000 },
  transfers: 20000,
  overage: 0,
  charges: {
    amount: "$525.00",
    projectedAmount: "$525.00",
    overageFee: "$0.00",
    refund: "-$0.00",
    total: "$525.00",
  },
};

export const FAKE_INTEGRATIONS = [
  {
    id: 1,
    name: "Quickbooks Online",
  },
  { id: 2, name: "Shopify Premium" },
  { id: 3, name: "Quiet Logistics" },
  {
    id: 4,
    name: "Quickbooks Desktop",
  },
  { id: 5, name: "Odoo" },
  { id: 6, name: "Shipstation" },
];

export const FAKE_USER = { id: 4, name: "Marc", isAdmin: true };

export const FAKE_CONNECTIONS_DATA = [
  {
    id: 1,
    name: "Shipstation",
    workflows: [
      {
        id: 12,
        name: "Send Orders",
        type: "Send",
      },
      {
        id: 15,
        name: "Get Inventory",
        type: "Get",
      },
    ],
  },
  {
    id: 2,
    name: "QuickBooks Online",
    workflows: [
      {
        id: 14,
        name: "Send Orders",
        type: "Send",
      },
    ],
  },
  {
    id: 2,
    name: "Shopify",
    workflows: [
      {
        id: 32,
        name: "Get Orders",
        type: "Get",
      },
      {
        id: 34,
        name: "Send Inventory",
        type: "Send",
      },
    ],
  },
];

export const FAKE_NOTIFICATIONS_DATA = [
  {
    id: 12,
    message: "Item with SKU 192783212-HHFII Not Found",
    workflow: "Send Inventory to Shopify Premium",
  },
  {
    id: 32,
    message: "Your QuickBooks credentials have expired. Please re-authenticate",
    workflow: "Send Order to QuickBooks",
  },
];

export const FAKE_CHART_DATA = (month) => {
  const zeros = { ...new Array(32).fill(0) };
  delete zeros[0];

  const rawData = {
    "2023-04": {
      days: 30,
      totalTransfers: 93,
      pricingTier: "Entrepreneur",
      datasets: [
        {
          id: "order",
          label: "",
          borderColor: "#6c2f66dd",
          backgroundColor: "#6c2f66cc",
          data: { ...zeros },
        },
        {
          id: "product",
          label: "Product",
          borderColor: "#5ac230dd",
          backgroundColor: "#5ac230cc",
          data: { ...zeros, 1: 7, 2: 1, 3: 14 },
        },
        {
          id: "invoice",
          label: "Invoice",
          borderColor: "#c5089edd",
          backgroundColor: "#c5089ecc",
          data: { ...zeros, 1: 10, 2: 12, 3: 7 },
        },
        {
          id: "shipment",
          label: "Shipment",
          borderColor: "#056e12dd",
          backgroundColor: "#056e12cc",
          data: { ...zeros, 1: 13, 2: 10, 3: 12 },
        },
        {
          id: "customer",
          label: "Customer",
          borderColor: "#e28de2dd",
          backgroundColor: "#e28de2cc",
          data: { ...zeros, 1: 4, 3: 3 },
        },
        {
          id: "orderlist",
          label: "",
          borderColor: "#5ac4f8dd",
          backgroundColor: "#5ac4f8cc",
          data: { ...zeros },
        },
      ],
    },
    "2023-03": {
      days: 31,
      totalTransfers: 836,
      pricingTier: "Entrepreneur",
      datasets: [
        {
          id: "order",
          label: "Order",
          borderColor: "#6c2f66dd",
          backgroundColor: "#6c2f66cc",
          data: {
            ...zeros,
            1: 2,
            2: 5,
            4: 4,
            6: 11,
            7: 9,
            9: 14,
            13: 4,
            14: 1,
            15: 4,
            17: 2,
            19: 14,
            20: 2,
            21: 7,
            22: 15,
            23: 4,
            25: 7,
            27: 6,
            29: 13,
            31: 7,
          },
        },
        {
          id: "product",
          label: "Product",
          borderColor: "#5ac230dd",
          backgroundColor: "#5ac230cc",
          data: {
            ...zeros,
            1: 14,
            3: 4,
            4: 11,
            5: 1,
            6: 14,
            7: 4,
            10: 8,
            11: 8,
            12: 7,
            13: 13,
            15: 6,
            16: 4,
            17: 6,
            18: 13,
            19: 3,
            20: 8,
            21: 9,
            22: 3,
            23: 4,
            25: 4,
            26: 15,
            28: 4,
            29: 14,
            30: 3,
          },
        },
        {
          id: "invoice",
          label: "Invoice",
          borderColor: "#c5089edd",
          backgroundColor: "#c5089ecc",
          data: {
            ...zeros,
            1: 7,
            2: 2,
            3: 4,
            7: 4,
            8: 15,
            9: 15,
            10: 10,
            13: 5,
            14: 7,
            15: 4,
            19: 2,
            21: 12,
            22: 1,
            24: 4,
            26: 6,
            27: 14,
            28: 4,
            29: 6,
            30: 15,
            31: 2,
          },
        },
        {
          id: "shipment",
          label: "Shipment",
          borderColor: "#056e12dd",
          backgroundColor: "#056e12cc",
          data: {
            ...zeros,
            1: 14,
            2: 8,
            3: 1,
            4: 15,
            5: 6,
            6: 13,
            8: 6,
            9: 1,
            11: 5,
            13: 3,
            14: 15,
            15: 2,
            16: 6,
            17: 8,
            18: 14,
            19: 14,
            20: 8,
            21: 15,
            22: 10,
            23: 13,
            26: 15,
            27: 10,
            28: 2,
            29: 11,
            30: 2,
            31: 1,
          },
        },
        {
          id: "customer",
          label: "Customer",
          borderColor: "#e28de2dd",
          backgroundColor: "#e28de2cc",
          data: {
            ...zeros,
            2: 2,
            3: 4,
            4: 1,
            5: 13,
            6: 12,
            7: 4,
            8: 9,
            9: 11,
            11: 1,
            12: 12,
            13: 6,
            14: 9,
            16: 15,
            17: 5,
            18: 5,
            20: 4,
            21: 1,
            22: 12,
            25: 13,
            26: 11,
            27: 10,
            28: 6,
            30: 2,
          },
        },
        {
          id: "orderlist",
          label: "",
          borderColor: "#5ac4f8dd",
          backgroundColor: "#5ac4f8cc",
          data: { ...zeros },
        },
      ],
    },
    "2023-02": {
      days: 28,
      totalTransfers: 813,
      pricingTier: "Entrepreneur",
      datasets: [
        {
          id: "order",
          label: "Order",
          borderColor: "#6c2f66dd",
          backgroundColor: "#6c2f66cc",
          data: {
            ...zeros,
            3: 10,
            4: 11,
            6: 10,
            7: 4,
            8: 8,
            9: 2,
            10: 10,
            11: 7,
            13: 10,
            14: 14,
            16: 13,
            18: 15,
            19: 2,
            20: 7,
            21: 14,
            23: 11,
            24: 1,
            26: 13,
            28: 12,
          },
        },
        {
          id: "product",
          label: "Product",
          borderColor: "#5ac230dd",
          backgroundColor: "#5ac230cc",
          data: {
            ...zeros,
            1: 7,
            2: 1,
            3: 7,
            5: 3,
            6: 1,
            7: 4,
            8: 13,
            13: 13,
            14: 9,
            16: 10,
            20: 7,
            21: 3,
            22: 4,
            23: 12,
            24: 1,
            25: 7,
            26: 9,
            27: 12,
          },
        },
        {
          id: "invoice",
          label: "Invoice",
          borderColor: "#c5089edd",
          backgroundColor: "#c5089ecc",
          data: {
            ...zeros,
            2: 3,
            3: 6,
            4: 11,
            6: 11,
            7: 15,
            9: 3,
            10: 4,
            11: 6,
            12: 6,
            16: 2,
            17: 8,
            18: 12,
            19: 3,
            20: 8,
            21: 4,
            22: 5,
            23: 12,
            26: 4,
            27: 14,
            28: 13,
          },
        },
        {
          id: "shipment",
          label: "Shipment",
          borderColor: "#056e12dd",
          backgroundColor: "#056e12cc",
          data: {
            ...zeros,
            1: 10,
            2: 3,
            3: 10,
            4: 9,
            5: 7,
            6: 9,
            9: 13,
            10: 13,
            12: 1,
            14: 9,
            16: 12,
            17: 12,
            18: 4,
            19: 7,
            20: 8,
            24: 7,
            25: 12,
            26: 14,
            27: 5,
            28: 2,
          },
        },
        {
          id: "customer",
          label: "",
          borderColor: "#e28de2dd",
          backgroundColor: "#e28de2cc",
          data: { ...zeros },
        },
        {
          id: "orderlist",
          label: "Order List",
          borderColor: "#5ac4f8dd",
          backgroundColor: "#5ac4f8cc",
          data: {
            ...zeros,
            2: 12,
            3: 13,
            4: 8,
            5: 11,
            7: 2,
            8: 7,
            10: 13,
            11: 13,
            14: 3,
            15: 13,
            16: 7,
            17: 13,
            18: 6,
            19: 7,
            20: 5,
            21: 11,
            22: 5,
            23: 14,
            24: 12,
            25: 1,
            26: 13,
            27: 2,
            28: 8,
          },
        },
      ],
    },
  };
  return rawData[month];
};

export const FAKE_INTEGRATION_IMAGES = [
  {
    imageUrl: require("assets/integration-logos/hubspot.png"),
    altText: "HubSpot",
  },
  {
    imageUrl: require("assets/integration-logos/listrak.png"),
    altText: "Listrak",
  },
  {
    imageUrl: require("assets/integration-logos/loyaltylion.png"),
    altText: "LoyaltyLion",
  },
  { imageUrl: require("assets/integration-logos/odoo.png"), altText: "Odoo" },
  {
    imageUrl: require("assets/integration-logos/qb-desktop.png"),
    altText: "QuickBooks Desktop",
  },
  {
    imageUrl: require("assets/integration-logos/quickbooks-online-logo.png"),
    altText: "QuickBooks Online",
  },
  {
    imageUrl: require("assets/integration-logos/salesforce.png"),
    altText: "Salesforce",
  },
  {
    imageUrl: require("assets/integration-logos/shipstation.png"),
    altText: "ShipStation",
  },
  {
    imageUrl: require("assets/integration-logos/shopify-plus.png"),
    altText: "Shopify Plus",
  },
  {
    imageUrl: require("assets/integration-logos/woocommerce.png"),
    altText: "WooCommerce",
  },
];

export const FAKE_INTEGRATION_DATA_STREAMS = [
  // TODO: Add descriptions to help user understand what a data stream is.
  // TODO: Expand max screen width in fieldset container in CustomizeDataTypes.js
  // when we have content in the descriptions

  {
    id: "orders",
    title: "Orders",
    description: "Sync Orders to QB",
  },
  {
    id: "invoices",
    title: "Invoices",
    description: "Sync Invoices to QB",
  },
  {
    id: "inventory",
    title: "Inventory",
    description: "Sync QB Inventory to your Shopify store",
  },
  // {
  //   id: "products",
  //   title: "Products",
  //   description: "",
  // },
  // {
  //   id: "customers",
  //   title: "Customers",
  //   description: "",
  // },
];
