import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarLink = ({ link, isVisible }) => {
  if (!isVisible) return null;

  return (
    <li>
      <NavLink to={link.route}>
        <FontAwesomeIcon icon={link.icon} />
        {link.text}
      </NavLink>
    </li>
  );
};

export default SidebarLink;
