const FeaturedTextBlock = () => {
  return (
    <div className="w-full">
      <div className="hero-text-container">
        <h1>
          Get started with FlowLink!
          <span className="flex pt-2 lg:pt-4 font-light">
            Connect Shopify & QuickBooks
          </span>
        </h1>
        <div>
          <p className="big-intro-text">
            Start automating Orders, Invoices, Products and / or Customers
            today! Follow these easy steps to set up and customize your
            integration.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeaturedTextBlock;
