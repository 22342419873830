import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import Button from "common/Button";

const AuthRow = ({
  onClick,
  isAuthenticated = false,
  imageUrl,
  disabled = false,
  name = "",
}) => {
  return (
    <div className="featured-integration-card">
      <div className="flex self-center p-4">
        <img src={imageUrl} alt={name} width={'210px'}/>
      </div>
      {isAuthenticated && (
        <div className="my-4 flex w-full items-center rounded-full  p-3 text-base font-medium text-off-black">
          <span className="ml-7 flex-1 text-center">Authorized</span>
          <FontAwesomeIcon icon={faCheck} className="fa-fw m-2" />
        </div>
      )}
      {onClick && (
        <Button
          onClick={onClick}
          className="button-3d"
          disabled={disabled}
        >
          <span className="ml-7 flex-1 text-center">Authorize</span>
          <FontAwesomeIcon icon={faChevronRight} className="fa-fw m-2" />
        </Button>
      )}
    </div>
  );
};

export default AuthRow;
