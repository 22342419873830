import { formatDate } from "util/helperFunctions";

const InvoiceInformation = ({ invoice }) => {
  const { plan, transfers, overage, charges, createdAt } = invoice;
  return (
    <>
      <div>{formatDate(createdAt)}</div>
      <div>{plan.type} Plan</div>
      <div>
        <div>Transfers Used:</div>
        <div>
          {transfers} / {plan.transferLevel}
        </div>
      </div>
      <div>
        <div>Transfer Overage:</div>
        <div>{overage}</div>
      </div>

      <div>Charges</div>

      <div>
        <div>First Month Amount:</div>
        <div>{charges.amount}</div>
      </div>
      <div>
        <div>Projected Amount:</div>
        <div>{charges.projectedAmount}</div>
      </div>
      <div>
        <div>Overage Fee:</div>
        <div>{charges.overageFee}</div>
      </div>
      <div>
        <div>Refund:</div>
        <div>{charges.refund}</div>
      </div>
      <div>
        <div>Total:</div>
        <div>{charges.total}</div>
      </div>
    </>
  );
};

export default InvoiceInformation;
