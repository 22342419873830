import { useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useAuth } from "hooks/useAuth";
import TextInput from "common/TextInput";
import Button from "common/Button";
import PasswordSuccess from "./PasswordSuccess";
import { SHOPIFY_QBO_URL } from "util/helperFunctions";
import { editPassword } from "util/apiQueries";

const PasswordEdit = () => {
  const { authData } = useAuth();
  const [searchParams] = useSearchParams();
  const reset_password_token = searchParams.get("reset_password_token");

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const mutation = useMutation((data) => editPassword(data), {
    onSuccess: (res) => {
      setIsLoading(false);
      setSuccess(res.ok);
      if (!res.ok) setError(res.msg);
    },
  });

  if (authData?.isLoggedIn) return <Navigate to={`${SHOPIFY_QBO_URL}/one`} />;

  if (success) {
    return (
      <PasswordSuccess
        title="Success"
        body="Your password has been updated! Please login to continue."
      />
    );
  }

  if (!reset_password_token) return <Navigate to="/login" />;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== passwordConfirmation) {
      setError("Password and password confirmation must match.");
      return;
    }

    setError(null);
    setIsLoading(true);
    mutation.mutate({
      password,
      reset_password_token,
    });
  };

  return (
    <div id="password-edti-page">
      <h1 className="auth-heading">Update Password</h1>

      <div className="auth-text">
        <p>Update your password below.</p>
      </div>
      <form
        onSubmit={handleSubmit}
        method="post"
        id="password-edit-form"
        className="auth-form"
      >
        {error && (
          <div className="form-error block" aria-live="polite">
            {error}
          </div>
        )}
        {success && (
          <div className="form-success block" aria-live="polite">
            {success}
          </div>
        )}
        <div className="form-col">
          <TextInput
            label="Password"
            hint="Must contain 10 characters, 2 numbers and a symbol"
            type="password"
            autoComplete="new-password"
            required={true}
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextInput
            label="Password Confirmation"
            type="password"
            autoComplete="new-password"
            required={true}
            name="passwordConfirmation"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
        </div>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <Button type="submit" className="button-secondary self-center">
            Change Password
          </Button>
        )}
        <p className="mt-2 text-center">
          <a href="/login">Back to Login</a>
        </p>
        <p className="mt-2 text-center">
          <a href="/password/reset">Back to password reset</a>
        </p>
      </form>
    </div>
  );
};

export default PasswordEdit;
