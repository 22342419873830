import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useAuth } from "hooks/useAuth";
import TextInput from "common/TextInput";
import Button from "common/Button";
import PasswordSuccess from "./PasswordSuccess";
import { SHOPIFY_QBO_URL } from "util/helperFunctions";
import { resetPassword } from "util/apiQueries";

const PasswordReset = () => {
  const { authData } = useAuth();

  const [email, setEmail] = useState();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const mutation = useMutation((email) => resetPassword(email), {
    onSuccess: (res) => {
      setIsLoading(false);
      setSuccess(res.ok);
      if (!res.ok) setError(res.msg);
    },
  });

  if (authData?.isLoggedIn) return <Navigate to={`${SHOPIFY_QBO_URL}/one`} />;

  if (success) {
    return (
      <PasswordSuccess
        title="Reset Password Link Set"
        body={`An email has been sent to ${email}.
        Please follw instructions to reset your password.`}
      />
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(null);
    setIsLoading(true);
    mutation.mutate(email);
  };

  return (
    <div id="password-reset-page">
      <h1 className="auth-heading">Forgot Password</h1>

      <div className="auth-text">
        <p>Enter your email address to reset your password.</p>
      </div>
      <form
        onSubmit={handleSubmit}
        method="post"
        id="password-reset-form"
        className="auth-form"
      >
        {error && (
          <div className="form-error block" aria-live="polite">
            {error}
          </div>
        )}
        {success && (
          <div className="form-success block" aria-live="polite">
            {success}
          </div>
        )}
        <div className="form-col">
          <TextInput
            placeholder="Email"
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <Button type="submit" className="button-secondary self-center">
            Send Reset Password Instructions
          </Button>
        )}
        <p className="mt-2 text-center">
          <a href="/login">Back to Login</a>
        </p>
      </form>
    </div>
  );
};

export default PasswordReset;
