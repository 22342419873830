import { useQuery } from "react-query";
import InvoiceInformation from "Pages/Companies/Invoices/InvoiceInformation";
// import { useAuth } from 'hooks/useAuth';
// import { getReq } from 'util/apiQueries';
import { FAKE_INVOICES, FAKE_NETWORK } from "util/fakeData";

const InvoiceCard = ({ companyId, invoiceId }) => {
  // const { logUserOut } = useAuth();

  const getCompanyInvoice = async (_cId, _invId) => {
    await FAKE_NETWORK();
    return FAKE_INVOICES[0];

    // getReq(`/companies/${cId}/invoices/${invId}`);
  };

  const { data, isFetching } = useQuery(["invoice", companyId, invoiceId], () =>
    getCompanyInvoice(companyId, invoiceId)
  );

  if (isFetching) return <div>Loading...</div>;

  if (!data) return <div>We couldn&apos;t find that invoice...</div>;
  return (
    <div id={`company-invoice-detail`} key={data.id}>
      <InvoiceInformation invoice={data} />
    </div>
  );
};

export default InvoiceCard;
