import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

const ProtectedRoute = () => {
  //grab current url
  const { pathname, search } = useLocation();

  const { authData } = useAuth();

  //if connection_id present in query params, store in local storage
  const connectionId = new URLSearchParams(search).get("connection_id");
  if (connectionId) localStorage.setItem("connection_id", connectionId);

  // eslint-disable-next-line
  if (!authData?.isLoggedIn) return <Navigate to={`/signup?redirect=${pathname}`} />;

  return <Outlet />;
};

export default ProtectedRoute;
