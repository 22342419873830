const IntegrationCard = ({ imageUrl, altText }) => {
  return (
    <div className="flex items-center justify-center rounded-xl bg-white px-8 py-6">
      <img
        src={imageUrl}
        height={100}
        width={300}
        alt={altText}
        className="h-[100px] w-[300px] object-contain"
      />
    </div>
  );
};

export default IntegrationCard;
