import StepsProgressIndicator from "./components/StepsProgressIndicator";
import MoreIntegrations from "./components/MoreIntegrations";
import flowLinkIcon from "assets/fl-branding/flowlink-icon-green.svg";
import annFace from "assets/fl-branding/ann-face.png";

const StepFive = ({ intOne, intTwo }) => {
  return (
    <div className="container max-w-screen-lg">
      <div className="featured-action-container">
        <StepsProgressIndicator
          currentStep={4}
          nameOne={intOne.name}
          nameTwo={intTwo.name}
        />
        <div className="card-dark flex items-center gap-6 p-4 md:p-2">
          <img src={annFace} alt="" className="hidden w-1/4 md:block" />
          <div>
            <p className="mb-2 text-2xl font-semibold text-fl-yellow">
              Congrats!
            </p>
            <p className="max-w-prose pr-4">
              Your data streams are ready in{" "}
              <img
                src={flowLinkIcon}
                role="presentation"
                className="hidden h-8 sm:inline"
                height={32}
              />{" "}
              Flowlink.
            </p>
            <div>
              <a
                className="text-lg font-bold text-fl-green hover:underline focus:underline active:text-fl-green-200"
                href="https://app.flowlink.io"
                rel="noreferrer"
                target="_blank"
              >
                Go to Dashboard<span className="sr-only"> (opens new window)</span>
              </a>
            </div>
            {/* Should be link or button depending on function */}
            {/* <button
              className=""
              onClick={() => {}}
            >
              Resend email verification
            </button> */}
          </div>
        </div>
      </div>
      <MoreIntegrations />
    </div>
  );
};

export default StepFive;
