import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { postInvoice } from "util/apiQueries";
import TextInput from "common/TextInput";
import Button from "common/Button";

const NewInvoiceForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { logUserOut } = useAuth();

  const mutateFn = async (id) => {
    const response = await postInvoice(id);
    if (response.status === 401) logUserOut();
    return navigate(`/companies/${id}/invoices/${response.id}`);
  };
  const mutateConfig = {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company", id] });
      queryClient.invalidateQueries({ queryKey: ["invoices", id] });
    },
  };
  const mutation = useMutation(mutateFn, mutateConfig);

  const handleSubmit = async (event) => {
    event.preventDefault();
    mutation.mutate(id);
  };

  if (mutation.isLoading) return <div>Saving Invoice...</div>;

  if (mutation.isError) return <div>Problem Saving Invoice!</div>;

  // if (mutation.isSuccess) //What to do here? Navigate to InvoiceDetail view?

  return (
    <form onSubmit={handleSubmit} method="post" id="new-invoice-form">
      <TextInput type="hidden" id="company-id" name="company-id" value={id} />
      <Button type="submit">Create New Invoice</Button>
    </form>
  );
};

export default NewInvoiceForm;
