import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

const ALL_STEPS = ["Authorize NAME", "Authorize NAME", "Customize data sync", "Confirm data sync"];

const Bubble = ({ difference }) => {
  const baseStyle =
    "flex aspect-square h-8 w-8 items-center justify-center rounded-full ";
  let bubbleStyle;

  if (difference > 0) {
    bubbleStyle = "border-4 border-fl-gray-600";
  } else if (difference === 0) {
    bubbleStyle = "bg-fl-yellow";
  } else bubbleStyle = "bg-fl-green-200 ";

  return (
    <div className={baseStyle + bubbleStyle}>
      {difference < 0 && (
        <FontAwesomeIcon icon={faCheck} className="text-lg text-white" />
      )}
    </div>
  );
};

const StepsProgressIndicator = ({ currentStep, nameOne, nameTwo }) => {
  ALL_STEPS[0] = ALL_STEPS[0].replace("NAME", nameOne);
  ALL_STEPS[1] = ALL_STEPS[1].replace("NAME", nameTwo);

  return (
    <div>
      <ol className="my-2 flex justify-between rounded-full bg-white md:mx-auto md:w-fit md:justify-center">
        {ALL_STEPS.map((step, index) => {
          return (
            <li
              key={index}
              className="aria-current:text-white aria-current:bg-fl-gray-600 group m-0.5 flex flex-row items-center rounded-full p-2 text-fl-gray-600 md:px-3 xl:px-4"
              aria-current={index === currentStep ? "step" : "false"}
            >
              <Bubble difference={index - currentStep} />
              <div className="group-aria-current:not-sr-only sr-only md:not-sr-only">
                <p className="px-2 xl:pl-4 text-sm md:text-base">{step}</p>
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default StepsProgressIndicator;
