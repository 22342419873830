import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
} from "@fortawesome/pro-solid-svg-icons";
import IntegrationCard from "./IntegrationCard";
import FlFaces from "assets/fl-branding/flowlink-faces.png";
import { FAKE_INTEGRATION_IMAGES } from "util/fakeData";

const integrations = FAKE_INTEGRATION_IMAGES;

const MoreIntegrations = () => {
  return (
    <div className="my-16 mx-auto flex w-full max-w-fit flex-col items-center justify-center gap-14 rounded-3xl bg-fl-gray-600 p-8 text-white md:p-12 lg:mt-20">
      <div className="flex max-w-screen-md items-center justify-center gap-8 xl:max-w-screen-lg">
        <img src={FlFaces} alt="" className="hidden w-1/4 md:block" />
        <div>
          <h2 className="mb-2 text-2xl font-semibold lg:text-3xl">
            Interested in the platforms below?
          </h2>
          <p className="max-w-prose text-lg xl:text-xl">
            <strong>
              Call or send us a message! Our dedicated customer service team
              sets us apart.
            </strong>{" "}
            We’re here to solve your integration nightmare with a custom
            solution.
          </p>
          <div className="mt-8 flex w-full flex-col items-center gap-4 sm:flex-row">
            <div className="flex-1">
              <a
                href="mailto:info@flowlink.io"
                className="group flex w-fit items-center text-2xl"
              >
                <FontAwesomeIcon
                  title="Email"
                  icon={faEnvelope}
                  className="text-fl-green"
                  fixedWidth
                  size="xl"
                />
                <span className="p-2 decoration-fl-green decoration-4 underline-offset-8 group-hover:underline">
                  info@flowlink.io
                </span>
              </a>
            </div>
            <div className="flex-1">
              <a
                href="tel:8772687356"
                className="group flex w-fit items-center text-2xl"
              >
                <FontAwesomeIcon
                  title="Phone number"
                  icon={faPhone}
                  className="text-fl-green"
                  fixedWidth
                  size="xl"
                />
                <span className="p-2 decoration-fl-green decoration-4 underline-offset-8 group-hover:underline">
                  877.268.7356
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 xl:grid-cols-3">
        {integrations.map((integration) => (
          <IntegrationCard
            imageUrl={integration.imageUrl}
            altText={integration.altText}
            key={integration.imageUrl}
          />
        ))}
      </div>
      <a
        className="text-lg font-bold text-fl-green hover:underline focus:underline active:text-fl-green-200"
        href="https://flowlink.io/integrations/"
        rel="noreferrer"
        target="_blank"
      >
        See more available integrations<span className="sr-only"> (opens new window)</span>
      </a>
    </div>
  );
};

export default MoreIntegrations;
