import React from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getReq } from "util/apiQueries";
import { formatDate } from "util/helperFunctions";
import IntegrationCell from "./IntegrationCell";

const CompanyDetail = () => {
  const { id } = useParams();

  const { data, isFetching } = useQuery(["company", id], () =>
    getReq(`/companies/${id}`)
  );
  const invRes = useQuery(["invoices", id], () =>
    getReq(`/companies/${id}/invoices`)
  );

  if (isFetching) return <div>Loading...</div>;

  if (!data) return <div>No Company Found</div>;

  const invoices = () => {
    if (invRes.isFetching) return <div>Loading...</div>;

    if (!invRes.data.invoices || invRes.data.invoices.length === 0)
      return <div>--</div>;

    return invRes.data?.invoices.map((invoice) => (
      <div key={invoice.id}>
        <Link to={`/companies/${data.id}/invoices/${invoice.id}`}>
          {formatDate(invoice?.createdAt)}
        </Link>
      </div>
    ));
  };

  return (
    <div id={`company-detail-page`} key={data.id}>
      {data.name}
      <div>Status: {data.status}</div>
      <div>
        <div>Invoices</div>
        {invoices()}
      </div>
      <Link to={`/companies/${data.id}/invoices/new`}>Create New Invoice</Link>
      <div>
        Integrations:
        {data.integrationIds?.map((id) => {
          return (
            <React.Fragment key={id}>
              <IntegrationCell id={id} />
              <br />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default CompanyDetail;
