import React from "react";
import { useQuery } from "react-query";
import ConnectionHeader from "./components/ConnectionHeader";
import { getReq } from "util/apiQueries";

// We currently only use the Shopify/QBO setup for this screen. Whenever a user logs in/signs up,
// they get redirected here.
// We'll need to do a couple things while this screen is loading
// 1. Check if they already have Shopify/QBO connections, if so redirect back to "/"
// 2. Check for Shopify headers and redirect to step 1 if we have the info
// (maybe send the data to FL too?)

const INT_ONE_NAME = "Shopify";
const INT_TWO_NAME = "QuickBooks Online";
// const INT_TWO_NAME = "QuickBooks Online (Dev)";

const Connection = ({ children }) => {
  const { data: integrations } = useQuery("integrations", () =>
    getReq(`/integrations`)
  );

  const intOne = integrations?.find((i) => i.name === INT_ONE_NAME);
  const intOneId = intOne?.id;
  const intTwo = integrations?.find((i) => i.name === INT_TWO_NAME);
  const intTwoId = intTwo?.id;

  const {
    isLoading: intOneLoading,
    isIdle: intOneIdle,
    data: intOneData,
  } = useQuery(
    ["integration_properties", intOneId],
    () => getReq(`/integrations/${intOneId}`),
    {
      enabled: !!integrations,
    }
  );

  const {
    isLoading: intTwoLoading,
    isIdle: intTwoIdle,
    data: intTwoData,
  } = useQuery(
    ["integration_properties", intTwoId],
    () => getReq(`/integrations/${intTwoId}`),
    {
      enabled: !!integrations,
    }
  );

  if (intOneLoading || intOneIdle || intTwoLoading || intTwoIdle) {
    return (
      <div>
        <ConnectionHeader />
        <div>Loading...</div>
      </div>
    );
  }

  // Future TODO: handle properties that aren't OAuth specific

  const oneProps = { ...intOne, ...intOneData, name: INT_ONE_NAME };
  const twoProps = { ...intTwo, ...intTwoData, name: INT_TWO_NAME };

  return (
    <div>
      <ConnectionHeader />
      {React.cloneElement(children, { intOne: oneProps, intTwo: twoProps })}
    </div>
  );
};

export default Connection;
