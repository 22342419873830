import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
// import { getReq } from "util/apiQueries";
import { FAKE_NETWORK, FAKE_NEW_INVOICE } from "util/fakeData";
import InvoiceInformation from "./InvoiceInformation";
import NewInvoiceForm from "./NewInvoiceForm";

const NewInvoice = () => {
  const { id } = useParams();

  const getNewInvoiceInfo = async (cId) => {
    await FAKE_NETWORK();
    return { ...FAKE_NEW_INVOICE, cId };

    // return await getReq(`/companies/${cId}/invoices/new`);
  };

  const { data, isFetching } = useQuery(["newInvoice", id], () =>
    getNewInvoiceInfo(id)
  );

  if (isFetching) return <div>Loading...</div>;

  const today = new Date();
  const invoice = data;
  invoice.createdAt = today.toDateString();
  return (
    <div id={`company-new-invoice`} key={data.id}>
      <InvoiceInformation invoice={invoice} />
      <NewInvoiceForm />
    </div>
  );
};

export default NewInvoice;
