import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NextBtn = ({
  onClick,
  disabled,
  title = "Next",
  className,
  iconStart,
  iconEnd,
  ...rest
}) => {
  const buttonClasses = ["button-3d", className].join(" ");
  const titleClasses = [
    "flex-1 text-center",
    iconStart && !iconEnd && "mr-4 pr-[1.25em]",
    iconEnd && !iconStart && "ml-4 pl-[1.25em]",
    !iconStart === !iconEnd && "mx-4",
  ].join(" ");

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={buttonClasses}
      {...rest}
    >
      {iconStart && <FontAwesomeIcon icon={iconStart} className="fa-fw m-2" />}
      <span className={titleClasses}>{title}</span>
      {iconEnd && <FontAwesomeIcon icon={iconEnd} className="fa-fw m-2" />}
    </button>
  );
};

export default NextBtn;
