import { SITE_URL } from "./helperFunctions";
import { FAKE_NEW_INVOICE, FAKE_NETWORK } from "./fakeData";

const ENDPOINTS = {
  GET_SHOPIFY_ORDERS: process.env.REACT_APP_GET_SHOPIFY_ORDERS_ID,
  SEND_QBO_ORDERS: process.env.REACT_APP_SEND_QBO_ORDERS_ID,
  GET_SHOPIFY_INVOICES: process.env.REACT_APP_GET_SHOPIFY_INVOICES_ID,
  SEND_QBO_INVOICES: process.env.REACT_APP_SEND_QBO_INVOICES_ID,
  GET_SHOPIFY_INVENTORY: process.env.REACT_APP_GET_SHOPIFY_INVENTORY_ID,
  GET_QBO_INVENTORY: process.env.REACT_APP_GET_QBO_INVENTORY_ID,
  SET_SHOPIFY_INVENTORY: process.env.REACT_APP_SET_SHOPIFY_INVENTORY_ID,
  GET_SHOPIFY_PRODUCTS: process.env.REACT_APP_GET_SHOPIFY_PRODUCTS_ID,
  GET_QBO_PRODUCTS: process.env.REACT_APP_GET_QBO_PRODUCTS_ID,
};

const dataStreamMap = (connOneId, connTwoId) => {
  return {
    orders: [
      workflowPayload('1. Get Orders', connOneId, ENDPOINTS.GET_SHOPIFY_ORDERS),
      workflowPayload('2. Send Orders', connTwoId, ENDPOINTS.SEND_QBO_ORDERS, { triggerIds: [1] })
    ],
    invoices: [
      workflowPayload('1. Get Invoices', connOneId, ENDPOINTS.GET_SHOPIFY_INVOICES),
      workflowPayload('2. Send Invoices', connTwoId, ENDPOINTS.SEND_QBO_INVOICES, { triggerIds: [1] })
    ],
    inventory: [
      workflowPayload('1a. Get Inventory', connOneId, ENDPOINTS.GET_SHOPIFY_INVENTORY, { interval: 1440 }),
      workflowPayload('1b. Get Inventory', connTwoId, ENDPOINTS.GET_QBO_INVENTORY, { interval: 1440 }),
      workflowPayload('2. Set Inventory', connOneId, ENDPOINTS.SET_SHOPIFY_INVENTORY, { triggerIds: [2] })
    ],
    products: [
      workflowPayload('1a. Get Products', connOneId, ENDPOINTS.GET_SHOPIFY_PRODUCTS),
      workflowPayload('1b. Get Products', connTwoId, ENDPOINTS.GET_QBO_PRODUCTS)
    ]
  };
};

function workflowPayload (name, connectionId, endpointId, options = {}) {
  const { triggerIds = [], interval = null } = options
  return {
    name,
    connection_id: connectionId,
    endpoint_id: endpointId,
    ...(triggerIds.length && { trigger_ids: triggerIds }),
    ...(interval && { interval: interval }),
  }
}

const handleResponse = async (response) => {
  if (response?.ok) {
    return {
      isAdmin: false,
      ok: true,
      msg: null,
    };
  }

  const text = await response.text();
  let msg;
  try {
    msg = JSON.parse(text).error.message;
  } catch (error) {
    console.log("error happened and we caught it", error);
    msg = "Something went wrong!";
  }

  return {
    isAdmin: false,
    ok: false,
    msg,
  };
};

export const getReq = async (url) => {
  // TODO: why does this loop?
  console.log(`Making GET request to: ${SITE_URL}${url}`);
  const response = await fetch(`${SITE_URL}${url}`, { credentials: "include" });
  if (response.status === 401) {
    throw new Error("Unauthorized");
  }
  return response.json();
};

export const postReq = async (url, body, methodOverride = "POST") => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const opts = {
    method: methodOverride,
    credentials: "include",
    headers: myHeaders,
    body,
  };
  console.log(
    `Making ${methodOverride} request to: ${SITE_URL}${url} with opts ${opts}`
  );
  const response = await fetch(`${SITE_URL}${url}`, opts);
  if (response.status === 401) {
    throw new Error("Unauthorized");
  }

  return handleResponse(response);
};

export const deleteReq = async (url) => {
  const response = await fetch(`${SITE_URL}${url}`, {
    method: "DELETE",
    credentials: "include",
  });
  if (response.status === 401) {
    throw new Error("Unauthorized");
  }

  return handleResponse(response);
}

export async function postInvoice(companyId) {
  // let url = `/companies/${companyId}`/invoices`;
  // fetch(url, { method: 'POST', headers, body: {} })

  await FAKE_NETWORK();
  return { ...FAKE_NEW_INVOICE, companyId };
}

export async function login(email, password) {
  const body = JSON.stringify({ email, password });
  return await postReq(`/sign_in`, body);
}

export async function userInfo() {
  const url = `/users/me`;
  return await getReq(url);
}

export async function logout() {
  // Server should nullify the HTTP Only Tokens
  const url = `/sign_out`;
  return await deleteReq(url);
}

export async function signup(data) {
  const body = JSON.stringify({
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    company_name: data.company,
    password: data.password,
    // The following are currently not supported nor used in FlowLink:
    // phone_number: data.phone,
    // password_confirmation: data.passwordConfirmation,
    // feedback: data.feedback,
  });
  return postReq(`/sign_up`, body);
}

export async function resetPassword(email) {
  const body = JSON.stringify({ email });
  return postReq(`/password/reset`, body);
}

export async function editPassword({ password, reset_password_token }) {
  const body = JSON.stringify({ password, reset_password_token });
  return postReq(`/password/update`, body, "PUT");
}

export async function createWorkflows({ dataTypes, connOneId, connTwoId }) {
  const dataStreams = dataStreamMap(connOneId, connTwoId)

  const workflows = dataTypes.flatMap((dt) =>
    dataStreams[dt].map((wf) => wf)
  );

  return await postReq(`/workflows/batch`, JSON.stringify({ workflows }));
}
