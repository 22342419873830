import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

import logo from "assets/fl-branding/flowlink-logo-bicolor-white.svg";
import icon from "assets/fl-branding/flowlink-icon-green.svg";

const ConnectionHeader = () => {
  return (
    <header className="relative flex w-full flex-row items-center justify-end bg-off-black p-2 text-off-white sm:justify-center mb-16 md:mb-28">
      <a
        href="https://www.shopify.com"
        className="absolute left-2 px-1 py-2 text-xl font-light text-fl-yellow underline hover:text-fl-green focus:text-fl-green"
      >
        <div className="items-middle flex flex-row gap-2">
          <FontAwesomeIcon icon={faChevronLeft} size="xl" />
          <div>Back to Shopify</div>
        </div>
      </a>
      <div className="sm:hidden">
        <img
          src={icon}
          role="img"
          alt="FlowLink"
          className="h-14"
          height={64}
        />
      </div>
      <div className="hidden sm:block">
        <img
          src={logo}
          role="img"
          alt="FlowLink"
          className="h-14"
          height={64}
        />
      </div>
    </header>
  );
};

export default ConnectionHeader;
